











































































































































































































import Bop from "@/models/Bop";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Guid } from "guid-typescript";
import Feb from "@/models/Feb";

@Component({})
export default class FebStep extends Vue {
  @Prop()
  private isEditable!: boolean;
  private localFeb: Feb | null = null;
  private get canBeEditedAfterRealised(): boolean {
    return (
      this.$route.query.isEdit !== undefined &&
      this.$route.query.isEdit === "true"
    );
  }
   private get bopShort(): String {
    return `${this.bop.siteName} - ${this.bop.bopModelName?.toString().toLowerCase()} ${this.bop.bopNumber ? " # ":""}${this.bop.bopNumber ?? ""}`;
  }

  private get bop(): Bop {
    return this.$store.state.bop;
  }
  private get feb(): Feb | null {
    return this.bop.feb;
  }
  private set feb(feb: Feb | null) {
    this.$store.commit("updateFeb", feb);
  }
  private get bopStatus() {
    return this.$store.state.bopStatuses
      ? this.$store.state.bopStatuses[this.bop.bopStatusKey]
      : "";
  }
  /**
   * Hooks
   */
  private created() {
    const localFeb = this.feb ?? new Feb();
    if(localFeb.id === Guid.createEmpty().toString()){
      localFeb.repereEquipements = this.bop.interventionPlace;
      localFeb.unity = this.bop.equipement;
      localFeb.workDescription = this.bop.name;
    }
    if (localFeb.nombrePlancherTravail) {
      localFeb.nombrePlancherTravail = parseFloat(
        localFeb.nombrePlancherTravail.toString()
      ).toString();
    }
    if (localFeb.chargeSpecifiquePlanchers) {
      localFeb.chargeSpecifiquePlanchers = parseFloat(
        localFeb.chargeSpecifiquePlanchers.toString()
      ).toString();
    }
    this.localFeb = localFeb;
  }

  private mounted() {
  }

  /**
   * Watchers
   */
  @Watch("localFeb", { deep: true })
  private onLocalFebCHange() {
    this.feb = this.localFeb;
  }
}
